<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Principais Indicadores</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
          <span class="key-title">Produção média (l/dia): </span>
          <span class="value-title">
            {{
              relatorio.producaoMedia
                ? relatorio.producaoMedia
                : 'Não informado'
            }}
          </span>
        </v-col>
        <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
          <span class="key-title">Proteína média:</span>
          <span class="value-title">
            {{
              relatorio.proteinaMediaUltimoMes
                ? relatorio.proteinaMediaUltimoMes + ' %'
                : 'Não informado'
            }}
          </span>
        </v-col>
        <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
          <span class="key-title">Gordura média:</span>
          <span class="value-title">{{
            relatorio.gorduraMediaUltimoMes
              ? relatorio.gorduraMediaUltimoMes + ' %'
              : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
          <span class="key-title">CPP média: </span>
          <span class="value-title">{{
            relatorio.cppMedia
              ? relatorio.cppMedia + ' UFC/ml * 10³'
              : 'Não informado'
          }}</span>
        </v-col>
        <!-- <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
          <span class="key-title">CPP após a visita: </span>
          <span class="value-title">{{
            relatorio.finalizedCpp
              ? relatorio.finalizedCpp + ' UFC/ml * 10³'
              : 'Não informado'
          }}</span>
        </v-col> -->
        <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
          <span class="key-title">CCS média: </span>
          <span class="value-title">{{
            relatorio.ccsMedia
              ? relatorio.ccsMedia + ' céls/ml * 10³'
              : 'Não informado'
          }}</span>
        </v-col>
        <!-- <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Porcentagem de conformidades: </span>
          <span class="value-title">{{
            relatorio.conformPercentege
              ? relatorio.conformPercentege + ' %'
              : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Porcentagem de não conformidades: </span>
          <span class="value-title">{{
            relatorio.notConformPercentege
              ? relatorio.notConformPercentege + ' %'
              : 'Não informado'
          }}</span>
        </v-col> -->
        <v-col v-if="relatorio.tiposDeOrdenha" cols="12" class="col-bordered">
          <span class="key-title">Tipo de ordenha: </span>
          <span v-if="relatorio.tiposDeOrdenhaManual" class="value-title">{{
            'Manual'
          }}</span>
          <span v-if="relatorio.tiposDeOrdenhaBaldeAoPe" class="value-title">{{
            'Balde ao pé'
          }}</span>
          <span
            v-if="relatorio.tiposDeOrdenhaTransferidor"
            class="value-title"
            >{{ 'Transferidor' }}</span
          >
          <span v-if="relatorio.tiposDeOrdenhaLinhaBaixa" class="value-title">{{
            'Circuito fechado / linha baixa'
          }}</span>
          <span v-if="relatorio.tiposDeOrdenhaLinhaMedia" class="value-title">{{
            'Circuito fechado / linha média'
          }}</span>
          <span v-if="relatorio.tiposDeOrdenhaLinhaAlta" class="value-title">{{
            'Circuito fechado / linha alta'
          }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: { convertNumberBR },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
