<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="500">
      <v-card>
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          enctype="multipart/form-data"
          lazy-validation
          @submit.prevent="updateGestor"
        >
          <v-card-title>
            <span class="text-h5">Configuração do relatório</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="finalizedCpp"
                    type="number"
                    label="Insira o valor do cpp após a visita *"
                    hint="Valor do cpp após a visita"
                    :rules="requiredRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>* indica campo obrigatório</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="close"> Cancelar </v-btn>
            <v-btn
              color="green darken-1"
              text
              :loading="loading.save"
              :disabled="loading.save"
              @click="update"
            >
              Atualizar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ReportService from '../../../services/api/reports'

export default {
  props: {
    value: Boolean,

    relatorio: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dialog: false,
      valid: true,

      finalizedCpp: '',

      requiredRules: [v => !!v || '* Obrigatório'],

      loading: {
        save: false,
      },
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  methods: {
    async update() {
      this.valid = this.$refs.form.validate()

      if (!this.valid) {
        return this.Toast().fire({
          icon: 'error',
          title: 'Preencha todos os campos obrigatórios!',
        })
      }

      this.loading.save = true

      try {
        const result = await ReportService.putField({
          id: this.relatorio.CodRelatorio,
          form: {
            cppContent: this.finalizedCpp,
          },
        })

        if (result.success) {
          this.Toast().fire({
            icon: 'success',
            title: 'CPP atualizado com sucesso!',
          })
        } else {
          this.Toast().fire({
            icon: 'error',
            title: 'Não foi possível atualizar o CPP!',
          })
        }
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Não foi possível atualizar o CPP!',
        })
      } finally {
        this.close()
      }
    },

    close() {
      this.show = false
      this.loading.save = false
      this.finalizedCpp = ''
    },
  },
}
</script>
