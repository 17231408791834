import JsPDF from 'jspdf'
import moment from 'moment'
import { ENV_APP } from '../../../env'
import { convertNumberBR } from '../../utils/masks'

const utilsRelatorio = {
  getDataAtual() {
    var monName = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ]
    var now = new Date()

    var str =
      'Relatório gerado em ' +
      now.getDate() +
      ' de ' +
      monName[now.getMonth()] +
      ' de ' +
      now.getFullYear() +
      ' às ' +
      formatDate(now, 'HH:mm:ss') +
      '.'
    return str
  },
  calcLacVacas(relatorio) {
    if (relatorio.VacasLactacao && relatorio.VacasSecas) {
      return `${convertNumberBR(
        100 *
          (parseInt(relatorio.VacasLactacao) /
            (parseInt(relatorio.VacasLactacao) +
              parseInt(relatorio.VacasSecas))),
      )} %`
    } else {
      return '-'
    }
  },
  calcLacRebanho(relatorio) {
    var totalRebanho = 0
    if (relatorio.VacasLactacao) {
      totalRebanho += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalRebanho += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.Novilhas) {
      totalRebanho += parseInt(relatorio.Novilhas)
    }
    if (relatorio.Recria) {
      totalRebanho += parseInt(relatorio.Recria)
    }
    if (relatorio.Aleitamento) {
      totalRebanho += parseInt(relatorio.Aleitamento)
    }
    if (relatorio.Machos) {
      totalRebanho += parseInt(relatorio.Machos)
    }
    if (totalRebanho > 0) {
      return `${convertNumberBR(
        100 * (parseInt(relatorio.VacasLactacao) / totalRebanho),
      )} %`
    } else {
      return '-'
    }
  },
  calcProdutividade(relatorio) {
    if (relatorio.VacasLactacao && relatorio.ProducaoMedia) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / parseInt(relatorio.VacasLactacao),
      )} L / dia`
    } else {
      return '-'
    }
  },
  calcProdutividadeTotal(relatorio) {
    var totalVacas = 0
    if (relatorio.VacasLactacao) {
      totalVacas += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalVacas += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.ProducaoMedia && totalVacas > 0) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / totalVacas,
      )} L / dia`
    } else {
      return '-'
    }
  },
}

function formatDate(date, format = 'DD/MM/YYYY') {
  return moment(date).format(format)
}

// function checkAddPage(doc, cursor, area, config) {
//   const { heightLimit, margin, tableLimit, line, startX } = config

//   if (cursor + area > heightLimit) {
//     doc.addPage()
//     cursor = margin
//     doc.rect(startX, cursor, tableLimit, line, 'F')
//   }

//   return cursor
// }

function header(doc, config, relatorio) {
  const logo1 = new Image()
  const logo2 = new Image()

  logo1.src = config.logo

  doc.addImage(logo1, 'PNG', config.margin, config.margin, 70, 55)

  if (relatorio.logoagroindustria) {
    logo2.src = relatorio.logoagroindustria
    doc.addImage(logo2, 'PNG', config.tableLimit - 50, config.margin, 73, 50)
  }

  doc.setFontSize(config.largefontSize).setFont('helvetica', 'bold')

  const widthOfString = doc.getTextWidth('Relatório Promilat')

  doc.text(
    'Relatório Promilat',
    config.tableLimit / 2 - widthOfString / 2 + config.margin,
    config.margin + 30,
  )

  doc.setFontSize(config.fontSize).setFont('helvetica', 'normal')
}

function subHeader(doc, _ref, _ref2) {
  const {
    fazenda: {
      produtor: { Nome: produtor },
      pessoajuridica: { NomeFantasia: fazenda },
      producer: { Matricula: matricula },
    },
    consultor: {
      pessoafisica: { Nome: consultor },
    },
    cidadefazenda,
    dataAtendimento,
    horaInicio,
    horaTermino,
    NumeroVisita,
  } = _ref
  const {
    boxHeight,
    gutterY,
    line,
    lineColor,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
    mediumFontSize,
    fontSize,
  } = _ref2

  doc.setFillColor(lineColor)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Informações básicas', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text('Fazenda:', startX + smallGutterX, startY + smallGutterY + boxHeight)

  doc
    .setFont('helvetica', 'bold')
    .text(fazenda, startX + smallGutterX, startY + gutterY + boxHeight)
    .setFont('helvetica', 'normal')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produtor(a):',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    produtor.substring(0, 25),
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Matrícula:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    matricula ? matricula : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.text(
    'Consultor(a):',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(consultor, startX + smallGutterX, startY + boxHeight * 3 + gutterY)

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Cidade:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    cidadefazenda ? cidadefazenda.Nome : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Data:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    dataAtendimento,
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 4,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do início:',
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    horaInicio,
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do término:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    horaTermino,
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 4,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Número da visita:',
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${NumeroVisita}ª visita`,
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F')
}

function indicadoresQualidade(doc, relatorio, _ref2) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = _ref2

  let TipoOrdenha
  if (relatorio.tiposDeOrdenha) {
    if (relatorio.tiposDeOrdenhaManual) TipoOrdenha = 'Manual'
    if (relatorio.tiposDeOrdenhaBaldeAoPe) TipoOrdenha = 'Balde ao Pé'
    if (relatorio.tiposDeOrdenhaTransferidor) TipoOrdenha = 'Transferidor'
    if (relatorio.tiposDeOrdenhaLinhaBaixa)
      TipoOrdenha = 'Circuito fechado / linha baixa'
    if (relatorio.tiposDeOrdenhaLinhaMedia)
      TipoOrdenha = 'Circuito fechado / linha média'
    if (relatorio.tiposDeOrdenhaLinhaAlta)
      TipoOrdenha = 'Circuito fechado / linha alta'
  }

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Principais Indicadores', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produção média (l/dia):',
    startX + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.producaoMedia ? `${relatorio.producaoMedia}` : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 3, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Proteína média:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.proteinaMediaUltimoMes
      ? `${relatorio.proteinaMediaUltimoMes} %`
      : 'Não informado',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Gordura média:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.gorduraMediaUltimoMes
      ? relatorio.gorduraMediaUltimoMes + ' %'
      : 'Não informado',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.text(
    'CPP média:',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.cppMedia ? `${relatorio.cppMedia} UFC/ml * 10³` : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'CCS média',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.ccsMedia
      ? `${relatorio.ccsMedia} céls/ml * 10³`
      : 'Não informado',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Porcentagem de conformidades: ',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    relatorio.conformPercentege ? relatorio.conformPercentege : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Porcentagem de não conformidades: ',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    relatorio.notConformPercentege
      ? relatorio.notConformPercentege
      : 'Não informado',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F') //superior

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F') //esquerda

  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F') //direita

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F') //inferior

  doc.text(
    'Tipo de ordenha:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    TipoOrdenha || 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )
}

function fotosAnexo(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (relatorio.visitasImagens && relatorio.visitasImagens.length) {
    cursor += smallBoxHeight
    if (cursor + boxHeight * 8 > heightLimit) {
      doc.addPage()
      cursor = margin + 10
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Imagens em anexo', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    var colInicio = startX

    if (cursor + boxHeight * 8 > heightLimit) {
      doc.addPage()
      cursor = margin + 10
    }

    doc
      .setFont('helvetica', 'bold')
      .text('Imagens da visita', startX, cursor)
      .setFont('helvetica', 'normal')

    cursor += smallBoxHeight

    let imagem = new Image()
    Object.keys(relatorio.visitasImagens).forEach(item => {
      try {
        if (item % 2 === 1) {
          colInicio = startX + 10 + tableLimit / 2
        } else {
          colInicio = startX + 40
        }

        if (cursor + boxHeight * 11 > heightLimit) {
          doc.addPage()
          cursor = margin + 10
        }
        imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.visitasImagens[item].id}`

        doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')

        if (
          item % 2 === 1 ||
          Object.keys(relatorio.visitasImagens).length - 1 == item
        ) {
          cursor += 200
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    })
    cursor += boxHeight

    colInicio = startX
  }

  return cursor
}
function rodapeImpressao(doc, config, cursor, text) {
  const { boxHeight, heightLimit, margin } = config
  const docWidth = doc.internal.pageSize.width
  const texto =
    utilsRelatorio.getDataAtual() +
    '       ' +
    `${ENV_APP.relUri}` +
    '        Pag. ' +
    text

  if (cursor + boxHeight * 2 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  doc.text(texto, docWidth / 2, heightLimit + 7, 'center')
}

export default data => {
  return new Promise(function (resolve, reject) {
    const MARGIN = 25
    const realStartY = 95
    var doc = new JsPDF({
      format: 'a4',
      compress: true,
      unit: 'pt',
    })
    var config = {
      startY: realStartY,
      startX: MARGIN,
      smallGutterY: 12,
      smallGutterX: 6,
      line: 0.3,
      tableLimit: doc.internal.pageSize.width - MARGIN - MARGIN,
      heightLimit: doc.internal.pageSize.height - MARGIN,
      margin: MARGIN,
      lineColor: '#000',
      boxHeight: 27,
      smallBoxHeight: 18,
      gutterX: 14,
      gutterY: 23,
      smallFontSize: 6.5,
      fontSize: 10,
      largefontSize: 16,
      mediumFontSize: 12,
      logo: 'img/' + `${ENV_APP.imgFileLogin}`,
      bodyStarY: realStartY + 25 * 8.5,
    }

    try {
      let cursor = 0
      header(doc, config, data)
      cursor += config.boxHeight
      subHeader(doc, data, config)
      cursor += config.boxHeight
      config.startY = config.startY + config.boxHeight * 5 + 20
      indicadoresQualidade(doc, data, config)
      cursor += config.boxHeight * 14
      cursor = fotosAnexo(doc, data, config, cursor)
      const pageCount = doc.internal.getNumberOfPages()

      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        rodapeImpressao(
          doc,
          config,
          cursor + config.boxHeight,
          String(i) + ' de ' + String(pageCount),
        )
      }
      doc
        .save(
          `relatorio-cpp-${data.NumeroVisita}-${data.fazenda.pessoajuridica.NomeFantasia}.pdf`,
          { returnPromise: true },
        )
        .then(() => {
          return resolve()
        })
    } catch (error) {
      reject(error)
    }
  })
}
