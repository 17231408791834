/* eslint-disable  */
import XLSX from 'xlsx-js-style'

export const createSheet = async ({ data, filename }) => {
  const wb = XLSX.utils.book_new()

  const {
    dataEfficiencyVisits,
    dataEfficiencyVistisByAVG,
    dataVisitsMade,
    dataEfficiency,
    dataUnconformities,
  } = data

  createUnconformities({ data: dataUnconformities, wb })

  createRealizedVisits({
    data: dataVisitsMade,
    wb,
    label: 'CONTROLE DE VISITAS',
  })

  createEfficiencyVisits({ data: dataEfficiency, wb })

  createRealizedVisits({
    data: dataEfficiencyVisits,
    wb,
    label: 'VISITAS REALIZADAS',
  })

  createRealizedVisits({
    data: dataEfficiencyVistisByAVG,
    wb,
    label: 'CPP MÉDIA GRUPO',
  })

  XLSX.writeFile(wb, `${filename}.xlsx`)
}

const createRealizedVisits = ({ data, wb, label }) => {
  data.forEach(item => {
    const ws = XLSX.utils.aoa_to_sheet([])

    const headers = item.headers.map(header => header.text)
    XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A1' })

    Object.keys(ws).forEach(key => {
      if (key.match(/^[A-Z]+1$/)) {
        ws[key].s = {
          font: {
            sz: 12,
            bold: true,
            color: { rgb: 'FFFFFF' },
          },
          fill: {
            fgColor: { rgb: '1f497d' },
          },
          alignment: {
            horizontal: 'center',
          },
        }
      }
    })

    ws['!cols'] = [{ wpx: 200 }]

    item.content.forEach((content, index) => {
      const row = []
      for (const element of item.headers) {
        row.push(content[element.value])
      }

      XLSX.utils.sheet_add_aoa(ws, [row], { origin: `A${index + 2}` })
    })

    XLSX.utils.book_append_sheet(wb, ws, label)
  })
}

const createEfficiencyVisits = ({ data, wb }) => {
  const result = data.map(item => {
    return {
      fazenda: item.fazenda.pessoajuridica.NomeFantasia,
      produtor: item.fazenda.produtor.Nome,
      laticinio:
        item.fazenda.fazenda_agroindustria[0].agroindustria.pessoajuridica
          .NomeFantasia,
      numeroVisita: item.numeroVisita,
      dataAtendimento: item.dataAtendimento,
      horaInicio: item.horaInicio,
      horaTermino: item.horaTermino,
      cppMedia: item.cppMedia,
      finalizedCpp: item.finalizedCpp,
      reduction: item.reduction,
      efectiveness: item.efectiveness,
    }
  })

  const ws = XLSX.utils.aoa_to_sheet([])

  result.forEach((item, index) => {
    const headers = headerDataEfficiencyVisits.map(header => header.text)
    XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A1' })

    Object.keys(ws).forEach(key => {
      if (key.match(/^[A-Z]+1$/)) {
        ws[key].s = {
          font: {
            sz: 12,
            bold: true,
            color: { rgb: 'FFFFFF' },
          },
          fill: {
            fgColor: { rgb: '1f497d' },
          },
          alignment: {
            horizontal: 'center',
          },
        }
      }
    })

    ws['!cols'] = [{ wpx: 200 }]
    ws['!cols'].push({ wpx: 200 })
    ws['!cols'].push({ wpx: 200 })
    ws['!cols'].push({ wpx: 70 })
    ws['!cols'].push({ wpx: 70 })
    ws['!cols'].push({ wpx: 70 })
    ws['!cols'].push({ wpx: 70 })
    ws['!cols'].push({ wpx: 70 })
    ws['!cols'].push({ wpx: 70 })
    ws['!cols'].push({ wpx: 70 })
    ws['!cols'].push({ wpx: 100 })

    const row = []
    for (const element of headerDataEfficiencyVisits) {
      row.push(item[element.value])
    }
    XLSX.utils.sheet_add_aoa(ws, [row], { origin: `A${index + 2}` })
  })

  // Adicione a planilha ao workbook
  XLSX.utils.book_append_sheet(wb, ws, 'VISITAS EFICIÊNCIA')
}

const headerDataEfficiencyVisits = [
  {
    text: 'Produtor',
    value: 'produtor',
  },
  {
    text: 'Propriedade',
    value: 'fazenda',
  },
  {
    text: 'Laticinio',
    value: 'laticinio',
  },
  {
    text: 'Número',
    value: 'numeroVisita',
  },
  {
    text: 'Data',
    value: 'dataAtendimento',
  },
  {
    text: 'Hora Inicio',
    value: 'horaInicio',
  },
  {
    text: 'Termino',
    value: 'horaTermino',
  },
  {
    text: 'CPP Antes da visita',
    value: 'cppMedia',
  },
  {
    text: 'CPP Depois da visita',
    value: 'finalizedCpp',
  },
  {
    text: '% Redução',
    value: 'reduction',
  },
  {
    text: 'Efetividade',
    value: 'efectiveness',
  },
]

const createUnconformities = ({ data, wb }) => {
  const parsedData = data.map(item => {
    return {
      ...item,
      farmName: item.fazenda.pessoajuridica.NomeFantasia,
      dataAtendimento: item.dataAtendimento,
    }
  })

  const ws = XLSX.utils.aoa_to_sheet([])

  parsedData.forEach((item, index) => {
    const headers = headerUnconformities.map(header => header.text)
    XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A1' })

    Object.keys(ws).forEach(key => {
      if (key.match(/^[A-Z]+1$/)) {
        ws[key].s = {
          font: {
            sz: 12,
            bold: true,
            color: { rgb: 'FFFFFF' },
          },
          fill: {
            fgColor: { rgb: '1f497d' },
          },
          alignment: {
            horizontal: 'center',
          },
        }
      }
    })

    ws['!cols'] = [{ wpx: 200 }]
    ws['!cols'].push({ wpx: 150 })
    ws['!cols'].push({ wpx: 250 })
    ws['!cols'].push({ wpx: 250 })
    ws['!cols'].push({ wpx: 250 })
    ws['!cols'].push({ wpx: 250 })
    ws['!cols'].push({ wpx: 250 })
    ws['!cols'].push({ wpx: 250 })
    ws['!cols'].push({ wpx: 250 })
    ws['!cols'].push({ wpx: 250 })

    const row = []
    for (const element of headerUnconformities) {
      row.push(item[element.value])
    }

    XLSX.utils.sheet_add_aoa(ws, [row], { origin: `A${index + 2}` })
  })

  XLSX.utils.book_append_sheet(wb, ws, 'NÃO CONFORMIDADES')
}

const headerUnconformities = [
  {
    text: 'Fazenda',
    value: 'farmName',
  },
  {
    text: 'Data de atendimento',
    value: 'dataAtendimento',
  },
  {
    text: 'Ambiente de descanso dos animais com acúmulo de barro',
    value: 'step_8_ambiental_de_descanso',
  },
  {
    text: 'Atraso no resfriamento do leite (Balde ao pé, Ordenha manual)',
    value: 'step_8_atraso_no_resfriamento_do_leite',
  },
  {
    text: 'Coleta do leite superior a 48 horas',
    value: 'step_8_coleta_do_leite_superior_a_48_horas',
  },
  {
    text: 'Desregulagem do tanque de resfriamento (termostato)',
    value: 'step_8_desregulagem_do_tanque',
  },
  {
    text: 'Limpeza do equipamento de ordenha inadequada',
    value: 'step_8_limpeza_do_equipamento',
  },
  {
    text: 'Sala de ordenha sem higienização adequada',
    value: 'step_8_sala_de_ordenha',
  },
  {
    text: 'Tanque não refrigera o leite a 4 ºC em duas horas',
    value: 'step_8_tanque_de_resfriamento',
  },
]
